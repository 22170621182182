import React, { useEffect } from "react"
import background from "./pre-new-year-sale-banner-bg.jpg"
import { Banner } from "../Banner"
import { getBannersLs, setBannersLs } from "src/helpers/localStorage"

export const PreNewYearSaleBanner = () => {
  const [isBannerShow, setIsBannerShow] = React.useState(false)

  useEffect(() => {
    const closed = getBannersLs()?.preNewYearSaleBanner?.closed

    if (!closed && Date.now() < +(new Date('2025-01-01'))) {
      setIsBannerShow(true)
    }
  }, [])

  const onClose = () => {
    const bannersLs = getBannersLs()
    const newDataLs = {
      ...bannersLs,
      preNewYearSaleBanner: {
        closed: true
      }
    }

    setBannersLs(newDataLs)
    setIsBannerShow(false)
  }

  const onClick = () => {
    window.open('https://p.chronos.ru/ny/rassvet', '_blank')
    onClose()
  }

  if (!isBannerShow) return null

  return (
    <Banner
      background={background}
      alt={'NY-sale'}
      onClick={onClick}
      onClose={onClose}
    />
  )
}
