import React from "react"
import { Close } from "src/components/ui/Close/Close"
import styled from "styled-components"

interface BannerProps {
  background: string
  alt: string
  onClick(): void
  onClose?(): void
}

export const Banner = (props: BannerProps) => {
  const {
    background,
    alt,
    onClick,
    onClose,
  } = props

  return (
    <Container>
      {onClose && <Close onClose={onClose}/>}
      <Link onClick={onClick}>
        <img src={background} alt={alt}/>
      </Link>
    </Container>
  )
}

const Container = styled.div`
  width: 36rem;
  max-width: 90vw;
  border-radius: 0.375rem;
  overflow: hidden;
  font-size: 0;
  position: relative;
  box-shadow: var(--workspace-box-shadow, 0px 3.2px 7.2px rgba(0, 0, 0, 0.4), 0px 0.6px 1.8px rgba(0, 0, 0, 0.2));
`

const Link = styled.image`
  display: block;
  cursor: pointer;

  img {
    width: 100%;
  }
`
