import { fetchAPI_REST, fillRoute } from 'src/helpers/api';
import { Dispatch } from 'redux';
import { routes } from 'src/routes';
import { searchParams } from '../../helpers/utils';

export const FORMATION_GET_SUCCESS = 'FORMATION_GET_SUCCESS';

export const getFormation = (id: number) => {
  return (dispatch: Dispatch) => {
      const route = fillRoute(routes.FORMATION, {id}, {
          id,
          textsForDawn: true
      })
      fetchAPI_REST(route)
          .then(data => { dispatch(getSuccess(data))  })
          .catch(error => { alert('Ошибка получения данных 2'); console.log('PERSON 2', error); });
  };
}

const getSuccess = (data: any) => {
  if(!data || data.failed) {

    // window.location.href = `${window.location.protocol}//${window.location.host}${window.location.search}`;
  }
  return {
      type: FORMATION_GET_SUCCESS,
      payload: data
  }
}

//dispatch(getSuccess(data))