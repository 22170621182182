import { CloseIcon } from "src/assets/img/icons"
import styled from "styled-components"
import React from "react";

interface CloseProps {
  className?: string
  top?: number
  right?: number
  onClose(): void
}

export const Close = (props: CloseProps) => {
  const {
    className = '',
    top = 2,
    right = 2,
    onClose,
  } = props

  return (
    <Component
      className={className}
      top={top}
      right={right}
      onClick={onClose}
    />
  )
}

const Component = styled(CloseIcon)<{top: number, right: number}>`
  position: absolute;
  top: ${p => `${p.top}rem`};
  right: ${p => `${p.right}rem`};

  width: 24px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: white;
  }
`
