import { setUser } from 'src/helpers/localStorage';
import { AppType, ChronosApi, S3Urls } from 'src/libs';
import { IRoute, routes } from 'src/routes';
import { IRefreshData } from "./types/RefreshData";

export const s3urls = new S3Urls();

export const mainApi = new ChronosApi(process.env.REACT_APP_STATIC_URL || '', process.env.REACT_APP_AUTH_URL || '', AppType.DAWN);

export const apiURL = `${process.env.REACT_APP_STATIC_URL}/api`;
export const apiURL2 = `${process.env.REACT_APP_STATIC_URL}/api`;

export const authURL = `${process.env.REACT_APP_AUTH_URL}/api/auth`;

interface ITask {
	route: any;
	data: any;
}

const tasks: ITask[] = [];

export const _query = (route: IRoute, data: any, withoutAuthToken: boolean = false) => {
	
	const headers: HeadersInit = {
		'Content-Type': 'application/json'
	}

	// всегда отправляем токен в заголовке
	if (true || !withoutAuthToken) {
		headers['token'] = mainApi?.token?.access || '';
	}

	const options: RequestInit = {
		method: route.method,
		credentials: 'include',
		headers,
		body: Object.keys(data || {})?.length ? JSON.stringify(data) : null,
	}

	return fetch(`${apiURL}${route.url}`, options);
}

export function fetchRest(route: IRoute, data: any) {
	if (!mainApi?.token?.access) {
		throw new Error('There is not token access')
	}

	console.log(`fetchRest`, route, data)
	return _query(route, data);
}

export async function fetchRestWithoutToken(route: IRoute, data?: any): Promise<any> {

	const queryResult = await _query(route, data, Boolean('withOutAuthToken'));

	console.log(`*** fetchRestWithoutToken ***
		route.url: ${route.url}
		queryResult: `, queryResult)

	if (!queryResult.ok && !route.url.includes('refresh')) {
		refreshToken((refreshData: IRefreshData) => {
			if (!refreshData.failed) {
				return fetchRestWithoutToken(route, data);
			} else {
				throw new Error('re Refresh token failed -' +  JSON.stringify(refreshData));
			}
		});
	} else {
		return queryResult;
	}

}


export async function refreshToken<TResult>(resultHandler: (refreshData: IRefreshData) => TResult): Promise<any> {
	return _query(routes.REFRESH_TOKEN, { token: mainApi?.token?.refresh }, Boolean('withOutAuthToken'))
		?.then(refreshResult => refreshResult.json())
		.then(refreshData => {
			if (!refreshData.failed) {
				const user = refreshData.data;
				mainApi.token = user.token;
				setUser(user);
			}

			resultHandler(refreshData);
		});
}


export const fetchAPI_do_REST = (route: IRoute, data: any) => {
	return new Promise((resolve, reject) => {
		fetchRest(route, data)
			.then(res => res.json())
			.then(firstData => {
				if (!firstData.failed) {
					resolve(firstData.data);
				} else {
					if (firstData.data.indexOf("INVALID_TOKEN") !== -1) {
						refreshToken((refreshData: IRefreshData) => {
							if (refreshData.failed) {
								reject(refreshData);
							} else {
								fetchRest(route, data)
									.then(secondResult => secondResult.json())
									.then(secondData => {
										if (!secondData.failed) resolve(secondData.data)
										else resolve(secondData)
									});
							}
						});
					} else {
						resolve(firstData);
					}
				}
			})
			.catch(err => {
				console.log('REST', route, err)
			});
	});
}

export async function fetchAPI_REST<TResult>(route: IRoute, data?: any): Promise<TResult> {
	const task = { route, data };
	let interval: NodeJS.Timer;
	tasks.push(task);

	const timer = new Promise((resolve) => {
		interval = setInterval(() => {
			if (tasks[0] === task) {
				resolve(task);
			}
		}, 100)
	});

	await timer;


	let res;
	try {
		res = await fetchAPI_do_REST(task.route, task.data);
		setTimeout(() => {
			clearInterval(interval);
			tasks.shift();
		}, 0)
		return res as TResult;
	} catch (e) {
		setTimeout(() => {
			clearInterval(interval);
			tasks.shift();
		}, 0)
		return e as TResult;
	}
}



export const fillRoute = (route: IRoute, urlParams: any, getParams?: any) => {
	let url = route.url;

	for (const key in urlParams) {
		url = url.replace(`:${key}`, urlParams[key]);
	}

	if (getParams) {
		url += '?';
		for (const key in getParams) {
			url += `${key}=${getParams[key]}&`;
		}
	}

	return {
		...route,
		url
	}
}
