import { fetchRestWithoutToken, fillRoute, fetchAPI_REST } from 'src/helpers/api'
import { Dispatch } from 'redux';
import { routes } from 'src/routes';

//import { signOut } from 'src/store/actions/user';

export const MAP_GET_SUCCESS = 'MAP_GET_SUCCESS';

export const get = (formationId: number) => {
    return (dispatch: Dispatch) => {
        const route = fillRoute(routes.CIRCLE, {id: formationId});
        fetchRestWithoutToken(route)
            .then(res => res.text())
            .then(data => {
                dispatch(getSuccess(formationId, data))
            })
            .catch(data => { alert(data); });
    }
}

const getSuccess = (formationId: number, map: string) => {
    return {
        type: MAP_GET_SUCCESS,
        payload: {
            formationId,
            map
        }
    }
}