import { IAuth } from "src/libs";

const USER_LS_NAME = 'user_v2.8';

const getDataLS = (key: string, dflt: any = null) => {
  try {
    const dataLS = localStorage.getItem(key)
    return dataLS ? JSON.parse(dataLS) : dflt
  } catch(e) {
    console.error(e)
    return dflt
  }
}

export const setUser = (data: IAuth) => {
    localStorage.setItem(USER_LS_NAME, JSON.stringify(data));
}

export const unsetUser = () => {
    localStorage.removeItem(USER_LS_NAME);
}

export const getUser = (): any => {
    const user = localStorage.getItem(USER_LS_NAME);
    return user ? JSON.parse(user): undefined;
}

export const getUserEditInfoShow = (id: number) => {
    const info = localStorage.getItem('userEditInfoShow_v1');
    const ls = info ? JSON.parse(info) : undefined;

    if (typeof ls === 'number') {
        localStorage.setItem('userEditInfoShow_v1', JSON.stringify([id]));
        return true
    }

    return (ls ?? []).includes(id)
}

// FIXME add check setUserEditInfoShow is number
export const setUserEditInfoShow = (id: number) => {
    const info = localStorage.getItem('userEditInfoShow_v1');
    const users = info ? JSON.parse(info) : [];

    localStorage.setItem('userEditInfoShow_v1', JSON.stringify([...users, id]));
}

export const getPaymentsRestrictionsLS = localStorage.getItem('payments_restrictions')

export const setPaymentsRestrictionsLS = (value: string) => localStorage.setItem('payments_restrictions', value)

interface BannersLs {
  preNewYearSaleBanner?: {
    closed?: boolean
  }
}

export const setBannersLs = (value: BannersLs) => localStorage.setItem('banners', JSON.stringify(value))
export const getBannersLs = (): BannersLs => getDataLS('banners', {})
